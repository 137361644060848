import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { IFingerRequest } from "../type";
import { imageURL } from "./config";
import { ImageZoomControls } from "./ImageZoomControls";
import Grid from "@mui/material/Grid";

interface IPassedProps {
  fingerprintLists?: IFingerRequest[];
  isCustomSrc?: boolean;
  setImagePage?: (isShowImage: boolean) => void;
  viewLargeImage?: (item: any) => void;
}
export const FingerPrintList = (props: IPassedProps) => {
  const { fingerprintLists, setImagePage, viewLargeImage } = props;
  //const [item, viewLargeImage] = React.useState<IFingerRequest>();

  const handleClickImage = (item: any) => {
    viewLargeImage && viewLargeImage(item);
    setImagePage && setImagePage(true);
  };

  const [width, setWidth] = React.useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  let wrapperCSS: any = {
    textAlign: "center",
    cursor: "pointer",
    width: 150,
    height: 190,
  };
  let customCSS: any = {
    textAlign: "center",
    cursor: "pointer",
    width: 150,
    height: 190,
  };
  if (isMobile) {
    customCSS = { ...customCSS, width: 100, height: 120 };
    wrapperCSS = { ...wrapperCSS, width: 130, height: 150 };
  }
  return (
    <React.Fragment>
      {(fingerprintLists && fingerprintLists?.length > 0 && (
        <ImageList cols={isMobile ? 1 : 5} sx={{ padding: 5 }}>
          {fingerprintLists?.map((item) => {
            return (
              item.image_finger && (
                <Grid key={item.finger_number} item xs={12} md={4} lg={2}>
                  <ImageListItem
                    sx={wrapperCSS}
                    onClick={() => {
                      console.log(item, "Items");
                      handleClickImage(item);
                      //setImagePage(true);
                    }}
                    key={item.id}
                  >
                    <img
                      style={customCSS}
                      src={`${item?.image_finger}`}
                      srcSet={`${item?.image_finger}`}
                      alt={`Finger - ${item.finger_number}`}
                      loading="lazy"
                    />
                    <ImageListItemBar
                      title={`Finger - ${item.finger_number}`}
                      position="below"
                    />
                  </ImageListItem>
                </Grid>
              )
            );
          })}
        </ImageList>
      )) ||
        null}

      {/* {item && <ImageZoomControls setImagePage={setImagePage} item={item} />} */}
    </React.Fragment>
  );
};
