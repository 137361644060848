import * as React from 'react';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { Grid, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { CaptureFinger } from '../Library/CaptureFinger';
import { ICustomer, IFingerRequest } from '../type';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { FingerPrintList } from './FingerPrintList';
import { DefaultFingersprints } from './DefaultFingersprints';
import { uploadFingerPrint } from '../Services';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';

interface IPassed {
  customerId?: string;
  fingers?: IFingerRequest[];
}
export const UploadFingerprints = ({
  customerId: customer_id,
  fingers,
}: IPassed) => {
  const [currentFinger, chooseFinger] = React.useState(1);
  const navigate = useNavigate();
  const customerId = customer_id ?? localStorage.getItem('customerId');

  if (fingers && fingers?.length > 0) {
    fingers?.forEach((fing) => {
      const index = DefaultFingersprints.findIndex(
        (df) => df.finger_number === fing?.finger_number
      );
      if (index !== -1) {
        DefaultFingersprints[index] = fing;
      }
    });
  }
  const [catpuredImages, handleCapture] =
    React.useState<IFingerRequest[]>(DefaultFingersprints);

  const captureFingerImage = (imgSrc: string) => {
    let fingersImg = catpuredImages;

    const index = fingersImg?.findIndex(
      (finger) => finger.finger_number === currentFinger
    );

    uploadFingerPrint({
      image_finger: imgSrc,
      finger_number: currentFinger,
      //@ts-ignore
      customer_id: parseInt(customerId) || 0,
    });

    fingersImg[index].image_finger = imgSrc;
    fingersImg[index].finger_number = currentFinger;
    fingersImg[index].isCaptured = true;

    //@ts-ignore
    fingersImg[index].customer_id = parseInt(customerId) || 0;

    handleCapture(fingersImg);
    chooseFinger(currentFinger + 1);
  };
  const SelectFinger = (finger_number: number | undefined = 1) => {
    chooseFinger(finger_number);
  };

  return (
    <React.Fragment>
      <Box>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {catpuredImages?.map((finger) => (
            <Grid item xs={4} lg={1.75}>
              <Badge
                sx={{ cursor: 'pointer' }}
                color={
                  finger.finger_number === currentFinger
                    ? 'primary'
                    : finger.isCaptured === true || finger.id
                    ? 'success'
                    : 'primary'
                }
                badgeContent={finger.finger_number}
                onClick={() => {
                  SelectFinger(finger.finger_number);
                }}
              >
                <TouchAppIcon
                  fontSize='large'
                  color={
                    finger.finger_number === currentFinger
                      ? 'primary'
                      : finger.isCaptured === true || finger.id
                      ? 'success'
                      : 'disabled'
                  }
                />
              </Badge>
            </Grid>
          ))}
          <Grid item xs={4} lg={1.75}>
            <ButtonGroup sx={{ textAlign: 'right', verticalAlign: 'top' }}>
              <Button
                onClick={() => {
                  if (!customerId) {
                    alert(
                      'Customer registration is not done. Please Add Customer first'
                    );
                    window.location.reload();
                    return false;
                  }
                  var res: any = CaptureFinger();
                  if (res && res.httpStaus === true) {
                    if (res.data.ErrorCode === '0') {
                      captureFingerImage(
                        `data:image/png;base64,${res.data.BitmapData}`
                      );
                      alert('Captured');
                    } else {
                      alert(res.data.ErrorDescription);
                    }
                  } else {
                    alert(res.err);
                  }
                }}
              >
                Capture
              </Button>
              <Button
                onClick={() => {
                  navigate('/retailer/customers');
                }}
              >
                Confirm
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        <Stack
          spacing={4}
          direction='row'
          sx={{ borderTop: '2px solid rgba(0, 0, 0, 0.12)', marginTop: 5 }}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3, pending: 4 }}
          >
            <Divider variant='fullWidth' />
            <FingerPrintList fingerprintLists={catpuredImages} />
          </Grid>
        </Stack>
        {/* <ImageZoomControls /> */}
      </Box>
    </React.Fragment>
  );
};
