export const statusList = [
  {
    id: 1,
    name: "Pending",
  },
  {
    id: 2,
    name: "In Progress",
  },
  {
    id: 3,
    name: "Completed",
  },
  {
    id: 4,
    name: "Rejected",
  },
  {
    id: 5,
    name: "Deleted",
  },
];
