import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Navigation } from "./Navigation";
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import UserManagement from "../Services/UserManagement";
import { IPassword } from "../type";
import { useEffect, useState } from "react";

const mdTheme = createTheme();
export const ChangePassword = () => {
  const [retailer, setRetailers] = React.useState<IPassword>();
  const [error, setError] = React.useState("");

  const handleChange = (e: any) => {
    setRetailers({ ...retailer, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    if (retailer) {
      const req = { ...retailer, email: localStorage.getItem("email") };

      UserManagement.updatePassword(req)
        .then((res) => {
          if (res) {
            setError("");
            alert("Password has been successfully changed");
          }
        })
        .catch((error) => {
          if (error?.message) {
            setError(error?.message);
          }
        });
    }
  };

  const confPwdError = retailer?.password !== retailer?.password_confirmation;
  const isDisabled =
    !retailer?.current_password ||
    !retailer?.password ||
    !retailer?.password_confirmation ||
    confPwdError;

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <Navigation />
        <Box component="main" maxWidth={"lg"}>
          <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    pb: 10,
                  }}
                >
                  <Typography variant="subtitle1">
                    {"Change Password"}
                  </Typography>
                  <Divider sx={{ mt: 1 }} />
                  {error && <Alert severity="error">{error}</Alert>}
                  <Grid
                    container
                    rowSpacing={1}
                    sx={{ mt: 2 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={6} lg={6} sx={{ mt: 2 }}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label={"Old Password"}
                        type={"password"}
                        required
                        value={retailer?.password}
                        name="current_password"
                        onChange={handleChange}
                        fullWidth
                        placeholder="..."
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={6} lg={6} sx={{ mt: 2 }}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label={"New Password"}
                        type={"password"}
                        required
                        value={retailer?.password}
                        name="password"
                        onChange={handleChange}
                        fullWidth
                        placeholder="..."
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={6} lg={6} sx={{ mt: 2 }}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label={"Confirm Password"}
                        type={"password"}
                        required
                        value={retailer?.password_confirmation}
                        name="password_confirmation"
                        onChange={handleChange}
                        error={
                          confPwdError && !!retailer?.password_confirmation
                        }
                        helperText={
                          confPwdError && retailer?.password_confirmation
                            ? "Confim password is not valid"
                            : ""
                        }
                        fullWidth
                        placeholder="..."
                        size="small"
                      />
                    </Grid>

                    <Grid
                      item
                      sx={{ mt: 1 }}
                      xs={12}
                      lg={12}
                      textAlign={"right"}
                    >
                      <Button
                        disabled={isDisabled}
                        variant="outlined"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
