import * as React from "react";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import UserManagement from "../Services/UserManagement";
import { StatusListDropdown } from "./StatusListDropdown";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";

const renderStatusListEditInputCell: GridColDef["renderCell"] = (params) => {
  return <StatusListDropdown {...params} />;
};

export const UserListColumns = () => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const viewDetail = (rowData: any) => {
    //@ts-ignore
    navigate(`/retailer/customer/${rowData.row.id}`, { state: rowData.row });
  };
  const gotoEditPage = (rowData: any) => {
    navigate(`/retailer/add-customer/${rowData.row.id}`, {
      state: rowData.row,
    });
  };

  const handleStatusAction = (e: any, id: any) => {
    const conf = window.confirm("Are you sure ?");
    if (conf && id) {
      UserManagement.changeUserStatus({
        id: id,
        status: e.target.checked ? 1 : 0,
      }).then((res) => {
        window.location.reload();
      });
    }
  };

  return [
    {
      field: "id",
      editable: false,
      headerName: "User ID",
      width: 150,
      sortable: false,
    },
    {
      field: "name",
      editable: false,
      headerName: "Name",
      width: 150,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      sortable: false,
      editable: false,
    },

    {
      field: "type",
      headerName: "Type",
      width: 150,
      sortable: false,
      editable: false,
    },

    {
      field: "parent",
      headerName: "Parent",
      width: 150,
      sortable: false,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      editable: false,
      width: 100,
      cellClassName: "actions",
      //@ts-ignore
      getActions: (row) => {
        return [
          //   <GridActionsCellItem
          //     icon={<FingerprintIcon />}
          //     label="View Fingerprint"
          //     className="textPrimary"
          //     onClick={() => viewDetail(row)}
          //     color="inherit"
          //   />,
          //   <GridActionsCellItem
          //     icon={<EditIcon />}
          //     label="Edit"
          //     className="textPrimary"
          //     onClick={() => gotoEditPage(row)}
          //     color="inherit"
          //   />,
          <GridActionsCellItem
            icon={
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      value={row.row.status}
                      defaultChecked={row.row.status === 1}
                      onChange={(e) => handleStatusAction(e, row.id)}
                    />
                  }
                  label={""}
                />
              </FormGroup>
            }
            label="Status"
            color="inherit"
          />,
        ];
      },
    },
  ];
};
