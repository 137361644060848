import * as React from "react";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useNavigate } from "react-router-dom";
import UserManagement from "../Services/UserManagement";
import { StatusListDropdown } from "./StatusListDropdown";
import { statusNameById } from "../common/statusNameById";

const renderStatusListEditInputCell: GridColDef["renderCell"] = (params) => {
  return <StatusListDropdown {...params} />;
};

export const Columns = () => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");

  const viewDetail = (rowData: any) => {
    //@ts-ignore
    navigate(`/retailer/customer/${rowData.row.id}`, { state: rowData.row });
  };
  const gotoEditPage = (rowData: any) => {
    navigate(`/retailer/add-customer/${rowData.row.id}`, {
      state: rowData.row,
    });
  };

  const handleDeleteAction = (id: string) => {
    const conf = window.confirm("Are you sure to delete ?");
    if (conf && id) {
      UserManagement.deleteCustomer(id).then((res) => {
        window.location.reload();
      });
    }
  };

  return [
    {
      field: "id",
      editable: false,
      headerName: "Customer ID",
      width: 150,
      sortable: false,
    },
    {
      field: "name",
      editable: false,
      headerName: "Customer Name",
      width: 150,
      sortable: false,
    },
    {
      field: "mobile",
      headerName: "Mobile Number",
      width: 150,
      sortable: false,
      editable: false,
    },
    {
      field: "dob",
      headerName: "DOB",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "aadhar_number",
      headerName: "Aadhar Number",
      width: 150,
      sortable: false,
      editable: false,
    },

    {
      field: "notes",
      headerName: "Notes",
      width: 150,
      sortable: false,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      editable: role && [1, 3].includes(parseInt(role)) ? true : false,
      renderCell: (params: GridRenderCellParams) => {
        return statusNameById(params.value as number);
      },
      renderEditCell: renderStatusListEditInputCell,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      editable: false,
      width: 100,
      cellClassName: "actions",
      //@ts-ignore
      getActions: (row) => {
        return [
          <GridActionsCellItem
            icon={<FingerprintIcon />}
            label="View Fingerprint"
            className="textPrimary"
            onClick={() => viewDetail(row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => gotoEditPage(row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteAction(row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
};
