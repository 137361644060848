import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Navigation } from "./Navigation";
import { Avatar, Box, Container } from "@mui/material";
import { AuthContext } from "../Services/AuthContext";
import { CopyrightComponent } from "./CopyrightComponent";
import { useContext } from "react";
import { deepPurple } from "@mui/material/colors";
import { fetchCustomerList, getCustomerPoints } from "../Services";

const mdTheme = createTheme();

export const DashboardComponent = () => {
  const { authtoken, setAuthenticated } = useContext(AuthContext);
  const token = localStorage.getItem("authKey");
  const [totalCustomer, setNumberOfCustomers] = React.useState(0);
  const [totalPoints, setCustomerPoints] = React.useState(0);
  React.useEffect(() => {
    if (token) {
      fetchCustomerList()
        .then((res) => {
          if (res.status === 200) {
            //@ts-ignore
            setNumberOfCustomers(res.data.customers.total);
            getCustomerPoints().then((result) => {
              //@ts-ignore
              setCustomerPoints(result?.data?.available_points);

              //@ts-ignore
              if (result?.data?.available_points) {
                localStorage.setItem(
                  "totalPoints",
                  //@ts-ignore
                  result?.data?.available_points
                );
              }
            });
          }
        })
        .catch((error) => {});
    }
  }, [token]);
  return (
    <AuthContext.Provider value={{ authtoken, setAuthenticated }}>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <Navigation />
          <Box
            component="main"
            maxWidth={"lg"}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
              <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: 240,
                    }}
                  >
                    <h4>Total Customers: {totalCustomer}</h4>
                    <h4>Total Available Points : {totalPoints}</h4>
                  </Paper>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: 240,
                    }}
                  >
                    Deposits
                  </Paper>
                </Grid>
                {/* Recent Orders */}
                <Grid item xs={12}>
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    Orders
                  </Paper>
                </Grid>
              </Grid>
              <CopyrightComponent sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};
