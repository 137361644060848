import * as React from "react";
import { DialogProps } from "@mui/material/Dialog";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  Modal,
  Slider,
  Typography,
} from "@mui/material";
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { SelectChangeEvent } from "@mui/material/Select";
import { useRef } from "react";
import { IFingerRequest } from "../type";
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty";
import Title from "./Title";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
interface IPassedProps {
  item?: IFingerRequest;
  setImagePage?: (isTrue: boolean) => void;
  customerName?: string;
  fingers?: IFingerRequest[];
}
const marks = [
  {
    value: 1,
  },
  {
    value: 5,
  },
  {
    value: 10,
  },
];

export const ImageZoomControls = (props: IPassedProps) => {
  const image = props.item;
  const [currentFinger, setCurrentFinger] = React.useState(image);
  const [activeFingerNum, setActiveFingerNum] = React.useState(
    image?.finger_number
  );
  const [open, setOpen] = React.useState(!!image?.image_finger);
  const [contrast, setContrast] = React.useState(100);
  const [isFlip, rotateImage] = React.useState(false);
  const [isInverted, doInvert] = React.useState(0);
  const [brightness, setBrightness] = React.useState(1);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("sm");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFullWidth(event.target.checked);
  };

  const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);

  const zoomToImage = () => {
    const { zoomToElement } = transformComponentRef.current;
    zoomToElement("imgExample");
  };

  const [width, setWidth] = React.useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  React.useEffect(() => {
    setOpen(!!image?.image_finger);
  }, [image?.image_finger, setOpen]);

  const Control = ({ zoomIn, zoomOut, resetTransform }: any) => (
    <React.Fragment>
      <Grid container xs={12} xl={12} sx={{ mb: 2, mt: 1 }}>
        <Grid xl={9} xs={9}>
          <ButtonGroup>
            <Button
              color="inherit"
              aria-label="reduce"
              onClick={() => {
                zoomOut();
              }}
            >
              {!isMobile && "ZOOM OUT"} <RemoveIcon fontSize="small" />
            </Button>
            <Button
              aria-label="increase"
              color="inherit"
              onClick={() => {
                zoomIn();
              }}
            >
              {!isMobile && "ZOOM IN"} <AddIcon fontSize="small" />
            </Button>
            <Button
              aria-label="Flip"
              color="inherit"
              onClick={() => {
                rotateImage(!isFlip);
              }}
            >
              {!isMobile && "FLIP"} <ThreeSixtyIcon />
            </Button>
            <Button
              aria-label="Flip"
              color="inherit"
              onClick={() => {
                doInvert(isInverted === 0 ? 1 : 0);
              }}
            >
              {!isMobile && " Inverse "}
              <FlipCameraAndroidIcon fontSize="small" />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid xl={3} xs={3}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            aria-label="reduce"
            onClick={() => {
              props?.setImagePage && props.setImagePage(false);
            }}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ mb: 3, mt: 2 }}>
        <Box sx={{ width: 320 }}>
          <Typography gutterBottom>Brightness</Typography>
          <Slider
            aria-label="ios slider"
            defaultValue={1}
            max={10}
            min={1}
            value={brightness}
            marks={marks}
            //@ts-ignore
            onChange={(e) => setBrightness(e.target.value)}
            valueLabelDisplay="on"
          />
        </Box>
      </Grid>
      <Grid container>
        <Box sx={{ width: 320, mb: 4 }}>
          <Typography>Contrast</Typography>
          <Slider
            aria-label="ios slider"
            defaultValue={100}
            max={500}
            min={100}
            value={contrast}
            //@ts-ignore
            onChange={(e) => setContrast(e.target.value)}
            valueLabelDisplay="on"
          />
        </Box>
      </Grid>
    </React.Fragment>
  );
  let wrapperCSS: any = {
    textAlign: "center",
    margin: "0 auto",
  };
  let leftIcon = {
    textAlign: "left",
    cursor: "pointer",
    mt: "10%",
    position: "absolute",
    left: "35%",
  };
  let rightIcon = {
    textAlign: "right",
    float: "right",
    cursor: "pointer",
    mt: "10%",
    position: "absolute",
    right: "35%",
  };
  let customCSS: any = {
    filter:
      "contrast(" +
      contrast +
      "%) brightness(" +
      brightness +
      ") invert(" +
      isInverted +
      ")",
    width: 170,
    height: 220,
  };
  if (isFlip) {
    customCSS = { ...customCSS, transform: "scaleX(-1)" };
  }
  // if (isInverted) {
  //   customCSS = { ...customCSS, filter: "invert(1)" };
  // } else {
  //   customCSS = { ...customCSS, filter: "invert(0)" };
  // }

  if (isMobile) {
    customCSS = { ...customCSS, width: 120, height: 150 };
    leftIcon = { ...leftIcon, left: "20%", mt: "15%" };
    rightIcon = { ...rightIcon, right: "20%", mt: "15%" };
  }
  const nextImage = (clickType: string) => {
    if (clickType === "right") {
      if (
        activeFingerNum &&
        props?.fingers?.length &&
        activeFingerNum <= props?.fingers?.length
      ) {
        setActiveFingerNum(activeFingerNum + 1);
        const nextfinger = props.fingers?.find(
          (fng) => activeFingerNum && fng.finger_number === activeFingerNum + 1
        );
        setCurrentFinger(nextfinger);
      }
    } else {
      if (activeFingerNum && activeFingerNum > 1) {
        const nextfinger = props.fingers?.find(
          (fng) => activeFingerNum && fng.finger_number === activeFingerNum - 1
        );
        setActiveFingerNum(activeFingerNum - 1);
        setCurrentFinger(nextfinger);
      }
    }
  };

  return (
    <Grid
      className="zoomController"
      container
      item
      xs={12}
      xl={12}
      sx={{
        backgroundColor: "black",
        color: "#f5f5f5",
        top: 0,
        pb: 40,
        bottom: 0,
      }}
    >
      <Box
        sx={{
          ml: 4,
          mt: 2,
          width: isMobile ? "90%" : "50%",
          margin: "0 auto",
        }}
      >
        <TransformWrapper>
          {(utils) => (
            <React.Fragment>
              <Title>
                {props?.customerName}'s finger {currentFinger?.finger_number}
              </Title>
              <Control {...utils} />
              {activeFingerNum && activeFingerNum > 1 && (
                <KeyboardArrowLeft
                  sx={leftIcon}
                  onClick={() => nextImage("left")}
                />
              )}
              {activeFingerNum &&
                props?.fingers?.length &&
                activeFingerNum < props?.fingers?.length && (
                  <KeyboardArrowRight
                    sx={rightIcon}
                    onClick={() => nextImage("right")}
                  />
                )}
              <TransformComponent contentStyle={wrapperCSS}>
                <img
                  cy-data={contrast}
                  style={customCSS}
                  src={currentFinger?.image_finger}
                  alt={currentFinger?.finger_number?.toString()}
                  id="fingerPrint"
                />
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
      </Box>
    </Grid>
  );
};
