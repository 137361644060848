import { PostMFS100Client } from "./PostMFS100Client";

export const CaptureFinger = () => {
  const quality = 60; //(1 to 100) (recommanded minimum 55)
  const timeout = 10; // seconds (minimum=10(recommanded), maximum=60, unlimited=0 )

  var MFS100Request = {
    Quality: quality,
    TimeOut: timeout,
  };
  var jsondata = JSON.stringify(MFS100Request);

  return PostMFS100Client("capture", jsondata);
};
