import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { RegistrationForm } from "./RegistrationForm";
import {
  Alert,
  AlertColor,
  Container,
  Grid,
  Paper,
  ThemeProvider,
} from "@mui/material";
import { Navigation } from "./Navigation";
import { theme } from "./theme";
import { UploadFingerprints } from "./UploadFingerprints";
import { TabPanel } from "./TabPanel";
import { useLocation, useParams } from "react-router-dom";
import { CustomerPointNotAvailableMsg } from "./CustomerPointNotAvailableMsg";
import { getCustomerPoints } from "../Services";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export interface IMessage {
  type: AlertColor;
  message: string;
}
export const defaultMsg: IMessage = {
  type: "info",
  message: "",
};
export const CustomerRegister = () => {
  const [value, setValue] = React.useState(0);
  const customerId = localStorage.getItem("customerId");
  const totalPoints = localStorage.getItem("totalPoints") || 0;

  const data = useLocation();

  const item = data.state;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [message, setMessage] = React.useState<IMessage>(defaultMsg);
  React.useEffect(() => {
    localStorage.removeItem("customerId");
    setMessage(defaultMsg);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <Navigation />
        <Box
          component="main"
          maxWidth={"lg"}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <Paper>
                  {totalPoints > 0 ? (
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                        >
                          <Tab label="Customer Details" {...a11yProps(0)} />
                          <Tab
                            disabled={
                              (message.type !== "success" && !item?.id) ||
                              !customerId
                            }
                            label="Scan Finger"
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                      <TabPanel value={value} index={0}>
                        <RegistrationForm
                          message={message}
                          setMessage={setMessage}
                          data={item}
                        />
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <UploadFingerprints
                          customerId={item?.id}
                          fingers={item?.fingers?.sort(
                            (a: any, b: any) =>
                              a.finger_number - b.finger_number
                          )}
                        />
                      </TabPanel>
                    </Box>
                  ) : (
                    <CustomerPointNotAvailableMsg />
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
