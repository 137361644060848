import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { getCSCAdmins } from "../Services";
import { ICSPAdmins, ICSPChildren } from "../type";

interface IPassed {
  click?: (uclId: number, retailerId?: number | undefined) => void;
}
export const FilterComponent = (props: IPassed) => {
  const [user, setUser] = React.useState(0);
  const [childUser, setChildUser] = React.useState("");
  const [cscAdmins, setCSCAdmins] = useState<ICSPAdmins[]>([]);
  const [retailers, setRetailers] = useState<ICSPChildren[]>([]);

  const handleChange = (event: SelectChangeEvent) => {
    setUser(parseInt(event.target.value));
    const child = cscAdmins.find(
      (admin) => admin?.id === parseInt(event.target.value)
    )?.children;

    //@ts-ignore
    child && setRetailers(child);
    props.click && props.click(+event.target.value);
  };
  const handleChildAction = (event: SelectChangeEvent) => {
    setChildUser(event.target.value);
    props.click && props.click(user, +event.target.value);
  };

  useEffect(() => {
    getCSCAdmins().then((res) => {
      setCSCAdmins(res.data?.cscadmins);
    });
  }, []);

  return (
    <div>
      <FormControl
        size="small"
        sx={{ mr: 1, mt: 1, minWidth: 150, width: "45%" }}
      >
        <InputLabel id="demo-simple-select-autowidth-label">
          Select CSP
        </InputLabel>
        <Select
          size="small"
          value={user.toString()}
          label="Select CSP"
          onChange={handleChange}
        >
          <MenuItem value={0}>{"All"}</MenuItem>
          {cscAdmins &&
            cscAdmins?.map((user) => (
              <MenuItem key={user?.id} value={user?.id}>
                {user?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl size="small" sx={{ mt: 1, minWidth: 150, width: "50%" }}>
        <InputLabel id="demo-simple-select-autowidth-label">
          Select Retailer
        </InputLabel>
        <Select
          size="small"
          value={childUser.toString()}
          label="Select Retailer"
          onChange={handleChildAction}
        >
          <MenuItem value={0}>{"All"}</MenuItem>
          {retailers?.length > 0 &&
            retailers?.map((retail) => (
              <MenuItem key={retail?.id} value={retail?.id}>
                {retail?.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};
