import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import React from "react";
import { statusList } from "../common/statusList";
import UserManagement from "../Services/UserManagement";

export const StatusListDropdown = (props: GridRenderCellParams) => {
  const { id } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({
      id: props.id,
      field: "status",
      value: event.target.value,
    });
    UserManagement.updateStatus({
      customer_id: id.toString(),
      status: event.target.value.toString(),
    });
  };

  return (
    <FormControl fullWidth>
      <Select name="status" size={"small"} onChange={handleChange}>
        {statusList.map((status) => (
          <MenuItem key={status.id} value={status.id}>
            {status.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
