import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Navigation } from "./Navigation";
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import UserManagement from "../Services/UserManagement";
import { ICSPAdmins, IRetailer } from "../type";
import { useEffect, useState } from "react";

const defaultValue: IRetailer = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
};
const mdTheme = createTheme();
export const AddUser = () => {
  const [retailer, setRetailers] = React.useState<IRetailer>(defaultValue);
  const [error, setError] = React.useState("");
  const [cssUsers, setUsers] = useState<any[] | []>([]);

  const handleChange = (e: any) => {
    setRetailers({ ...retailer, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    UserManagement.register(retailer)
      .then((res) => {
        if (res) {
          setError("");
          alert("User added successfully");
        }
      })
      .catch((error) => {
        if (error?.message) {
          setError(error?.message);
        }
      });
  };
  const isDisabled =
    !retailer.name ||
    !retailer.email ||
    !retailer.password ||
    !retailer.password_confirmation ||
    !retailer.role;

  const confPwdError = retailer.password !== retailer.password_confirmation;
  const roles = [
    {
      name: "UCL",
      id: 1,
    },
    {
      name: "Retailer",
      id: 2,
    },
  ];

  useEffect(() => {
    UserManagement.getCscusers()
      .then((res) => {
        //@ts-ignore
        setUsers(res.data?.cscusers);
      })
      .catch((error) => {
        setUsers([]);
      });
  }, []);

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <Navigation />
        <Box component="main" maxWidth={"lg"}>
          <Container maxWidth="lg" sx={{ mt: 10 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    pb: 10,
                  }}
                >
                  <Typography variant="subtitle1">
                    {"Add CSP Admin/Retailers"}
                  </Typography>
                  <Divider sx={{ mt: 1 }} />
                  {error && <Alert severity="error">{error}</Alert>}
                  <Grid
                    container
                    rowSpacing={1}
                    sx={{ mt: 2 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={6} lg={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label={"Name"}
                        required
                        value={retailer.name}
                        name="name"
                        onChange={handleChange}
                        fullWidth
                        placeholder="..."
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label={"Email"}
                        type={"email"}
                        required
                        value={retailer.email}
                        name="email"
                        onChange={handleChange}
                        fullWidth
                        placeholder="..."
                        size="small"
                      />
                    </Grid>

                    <Grid item xs={6} lg={6} sx={{ mt: 2 }}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label={"Password"}
                        type={"password"}
                        required
                        value={retailer.password}
                        name="password"
                        onChange={handleChange}
                        fullWidth
                        placeholder="..."
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={6} lg={6} sx={{ mt: 2 }}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        label={"Confirm Password"}
                        type={"password"}
                        required
                        value={retailer.password_confirmation}
                        name="password_confirmation"
                        onChange={handleChange}
                        error={confPwdError && !!retailer.password_confirmation}
                        helperText={
                          confPwdError && retailer.password_confirmation
                            ? "Confim password is not valid"
                            : ""
                        }
                        fullWidth
                        placeholder="..."
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={6} lg={6} sx={{ mt: 2 }}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="selectRole">Select Role</InputLabel>
                        <Select
                          size="small"
                          value={retailer.role}
                          name="role"
                          label="Select Role"
                          fullWidth
                          onChange={handleChange}
                        >
                          {roles?.length > 0 &&
                            roles?.map((role) => (
                              <MenuItem key={role?.id} value={role?.id}>
                                {role?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} lg={6} sx={{ mt: 2 }}>
                      <FormControl size="small" fullWidth>
                        <InputLabel id="selectRole">Select UCL</InputLabel>
                        <Select
                          size="small"
                          value={retailer.parent}
                          label="Select UCL"
                          name="parent"
                          fullWidth
                          onChange={handleChange}
                        >
                          {cssUsers?.length > 0 &&
                            cssUsers?.map((user: ICSPAdmins) => (
                              <MenuItem key={user?.id} value={user?.id}>
                                {user?.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      sx={{ mt: 1 }}
                      xs={12}
                      lg={12}
                      textAlign={"right"}
                    >
                      <Button
                        disabled={isDisabled}
                        variant="outlined"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
