import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CopyrightComponent } from "./CopyrightComponent";
import { validateLogin } from "../Services";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Services/AuthContext";
import { IResponse } from "../type";
import { useContext } from "react";
import Alert from "@mui/material/Alert";
import { defaultMsg, IMessage } from "./CustomerRegister";

const theme = createTheme();

export default function LoginComponent() {
  const navigate = useNavigate();
  const { setAuthenticated } = useContext(AuthContext);
  const [message, setMessage] = React.useState<IMessage>(defaultMsg);
  const authKey = localStorage.getItem("authKey");
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    validateLogin({
      email: data.get("email"),
      password: data.get("password"),
    })
      .then((response: IResponse) => {
        if (response.status === 200 && response && response?.data) {
          //@ts-ignore
          localStorage.setItem("authKey", response.data.token);
          //@ts-ignore
          localStorage.setItem("role", response.data.user?.role?.toString());
          //@ts-ignore
          localStorage.setItem("name", response.data.user?.name);
          //@ts-ignore
          localStorage.setItem("email", data.get("email"),);
          setAuthenticated(response.data.token);
          //<AuthContext.Provider value={authProvider} />;
          window.location.reload();
        }
      })
      .catch((error) => {
        setMessage({
          type: "error",
          message: "Oops, user credentials is not correct",
        });

        //<AuthContext.Provider value={authProvider} />;
        // navigate("/admin");
      });
  };

  React.useEffect(() => {
    setMessage(defaultMsg);
    if (authKey) {
      navigate("/retailer");
    }
    // if (message.type === "success") {
    //   setValue(1);
    // }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            {message.type && message.message !== "" && (
              <Alert sx={{ mb: 2 }} severity={message.type}>
                {message.message}
              </Alert>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <CopyrightComponent sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
