import axios from "axios";
import {
  ICustomer,
  IFingerRequest,
  ILoginRequest,
  IPassword,
  IPointReq,
  IRetailer,
  IUpdateStatusRequest,
  IUser,
} from "../type";
import { apiBaseURL } from "../config";
import { GridRowId } from "@mui/x-data-grid";

export default class UserManagement {
  public static baseURL = apiBaseURL;
  private static token = localStorage.getItem("authKey");
  public static config = {
    Authorization: "Bearer " + this.token,
  };
  /**
   * get user to get all users list
   * filter options
   */
  public static get(params: IUser): Promise<Response> {
    return axios
      .get(`${this.baseURL}/users`, {
        params: params,
        headers: this.config,
      })
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  /**
   * login
   * @param: user details
   * @output: user details if success
   */
  public static login(loginReq: ILoginRequest): Promise<Response> {
    return axios
      .post(`${this.baseURL}/login`, {
        ...loginReq,
      })
      .then((response) => {
        return Promise.resolve(response?.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  /**
   * register
   * @param: user details
   * @output: user details if success
   */
  public static register(req: IRetailer): Promise<Response> {
    return axios
      .post(
        `${this.baseURL}/register`,
        {
          ...req,
        },
        {
          headers: this.config,
        }
      )
      .then((response) => {
        return Promise.resolve(response?.data);
      })
      .catch((error) => {
        return Promise.reject(error?.response?.data);
      });
  }
  /**
   * customers register
   * @param: customers details
   * @output: customers details if success
   */
  public static customerRegister(req: ICustomer): Promise<Response> {
    return axios
      .post(
        `${this.baseURL}/customers`,
        {
          ...req,
        },
        {
          headers: this.config,
        }
      )
      .then((response) => {
        return Promise.resolve(response?.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  /**
   * customers register
   * @param: customers details
   * @output: customers details if success

   */
  public static updateRegister(req: ICustomer): Promise<Response> {
    return axios
      .put(
        `${this.baseURL}/customers/${req.id}`,
        {
          ...req,
        },
        {
          headers: this.config,
        }
      )
      .then((response) => {
        return Promise.resolve(response?.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  /**
   * @s3-uploader
   */
  public static uploadFingerPrint(req: IFingerRequest): Promise<Response> {
    return axios
      .post(
        `${this.baseURL}/s3-uploader`,
        {
          ...req,
        },
        {
          headers: this.config,
        }
      )
      .then((response) => {
        return Promise.resolve(response?.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *  Get customer list
   */
  public static getCustomers(req?: any): Promise<Response> {
    return axios
      .get(`${this.baseURL}/customers`, {
        params: req,
        headers: this.config,
      })
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }
  /**
   *  Get customer list
   */
  public static deleteCustomer(customerId: string): Promise<Response> {
    return axios
      .delete(`${this.baseURL}/customers/${customerId}`, {
        headers: this.config,
      })
      .then((response) => {
        return Promise.resolve(response?.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * update status
   */
  public static updateStatus(req: IUpdateStatusRequest): Promise<Response> {
    return axios
      .post(
        `${this.baseURL}/change-status`,
        {
          ...req,
        },
        {
          headers: this.config,
        }
      )
      .then((response) => {
        return Promise.resolve(response?.data);
      })
      .catch((error) => {
        return Promise.reject(error?.response?.data);
      });
  }

  /**
   *  get customer points
   */
  public static getCustomerPoints(): Promise<Response> {
    return axios
      .get(`${this.baseURL}/points`, {
        headers: this.config,
      })
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }

  /**
   *  getCSCAdmins
   */
  public static getCSCAdmins(): Promise<any> {
    return axios
      .get(`${this.baseURL}/cscadmins`, {
        headers: this.config,
      })
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }

  /** Save Points */
  public static savePoints(req: IPointReq): Promise<Response> {
    return axios
      .post(
        `${this.baseURL}/points`,
        {
          ...req,
        },
        {
          headers: this.config,
        }
      )
      .then((response) => {
        return Promise.resolve(response?.data);
      })
      .catch((error) => {
        return Promise.reject(error?.response?.data);
      });
  }
  /**
   *  Get cscusers list
   */
  public static getCscusers(req?: any): Promise<Response> {
    return axios
      .get(`${this.baseURL}/cscusers`, {
        params: req,
        headers: this.config,
      })
      .then((result) => {
        return Promise.resolve(result?.data);
      })
      .catch((error) => {
        Promise.reject(error);
      });
  }
  public static updatePassword(req: IPassword): Promise<Response> {
    return axios
      .post(
        `${this.baseURL}/update-password`,
        {
          ...req,
        },
        {
          headers: this.config,
        }
      )
      .then((response) => {
        return Promise.resolve(response?.data);
      })
      .catch((error) => {
        return Promise.reject(error?.response?.data);
      });
  }
  public static changeUserStatus(req: any): Promise<Response> {
    return axios
      .post(
        `${this.baseURL}/change-user-status`,
        {
          ...req,
        },
        {
          headers: this.config,
        }
      )
      .then((response) => {
        return Promise.resolve(response?.data);
      })
      .catch((error) => {
        return Promise.reject(error?.response?.data);
      });
  }
  public static multipleDelete(
    url: string,
    req: GridRowId[]
  ): Promise<Response> {
    return axios
      .post(
        `${this.baseURL}${url}`,
        {
          ids: req,
        },
        {
          headers: this.config,
        }
      )
      .then((response) => {
        return Promise.resolve(response?.data);
      })
      .catch((error) => {
        return Promise.reject(error?.response?.data);
      });
  }
}
