export const formatUser = (users: any) => {
  const list: any = [];
  users?.forEach((user: any) => {
    list.push({
      id: user.id,
      name: user.name,
      email: user.email,
      type: "UCL",
      parent: user.name,
      status: user.status,
    });
    if (user?.children && user?.children?.length > 0) {
      user?.children?.forEach((child: any) => {
        list.push({
          id: child.id,
          name: child.name,
          email: child.email,
          type: "Retailer",
          parent: user.name,
          status: child.status,
        });
      });
    }
  });
  return list;
};
